.diagonal-text {
    word-wrap: break-word; /* Allows breaking long words */
    overflow-wrap: break-word; /* Ensures the text stays within the container */
    white-space: normal; /* Allows text to wrap to the next line */
}
.logo {
    width: 100px; /* Adjust the size of the logo */
    height: auto;
    margin-top: 20px; /* Adjust the spacing as needed */
    display: block;
    margin-left: auto;
    margin-right: auto; /* Center the logo */
}
